import '/src/css/app.pcss';

/* JS */
import "lazysizes";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

window.disableBodyScroll = disableBodyScroll;
window.enableBodyScroll = enableBodyScroll;

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import Alpine from "alpinejs";

window.Alpine = Alpine;
Alpine.start();


window.onload = () => {
  const app = document.getElementById('app')
  if (app) {
    app.style.opacity = '1.0'
  }
}